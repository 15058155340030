@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');


/* VARS */
:root{
  --dark : #181825;
  --white : #ffffff;
  --primary : #3EFFE8;
  --secondary : #8C0FEE;
  /* FONT */
  font-size: 62.5%;
  --font-size-global : 1.26rem;
  --line-height-global : 1.3;
  --letter-spacing-global : 0.1rem;
  --font-family : "monument_extendedbold", "Helvetica", "Arial", sans-serif;
  /*  */
  --padding-global : clamp(0rem, 5vw, 2.5rem);
  --length-logo : 5.6rem;
}


/*  */
/* RESET */
/*  */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html, body{
  overflow: hidden;
}

body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-global);
  font-family: var(--font-family);
  color: var(--color-secondary);
  background-color: var(--dark);
  color : var(--white);
}

li{
  list-style: none;
}

a{
  text-decoration: none;
  color : var(--white);
}

video{
  object-fit: cover;
}

/*  */
/* TITLE */
/*  */
h1{
  font-size:clamp(5.3rem, 10.6vw, 13rem);
  line-height: 0.77;
  font-family: 'monument_extendedblack';
  text-transform: uppercase;
  justify-self: flex-start;
  min-width: 50%;
}

/*  */
/* HEADER */
/*  */
header{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: var(--padding-global);
  width: 100%;
  align-items: flex-end;
  gap : 3.5vw 7vw;
  opacity: 0;

  &.font-loaded{
    opacity: 1;
  }

  /*  */
  /* GIT */
  /*  */
  & > ul{
    font-family: 'monument_extendedregular';
    text-transform: uppercase;
    font-size: var(--font-size-global);
    line-height: var(--line-height-global);
    letter-spacing: var(--letter-spacing-global);

    a{
      transition: opacity 200ms ease;

      &:hover{
        opacity: .75;
      }
    }
  }
}

/*  */
/* NAV */
/*  */
nav[role=navigation]{

  ul{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  li{
    line-height: var(--line-height-global);
  }

  a{
    position: relative;

    &::before{
      $size-bullet : 8px;
      content: "";
      display: block;
      position: absolute;
      right: calc(100% + #{$size-bullet});
      top: 50%;
      width: $size-bullet;
      height: $size-bullet;
      border-radius: $size-bullet;
      margin-top: -(calc($size-bullet / 2));
      background-color: var(--white);
      opacity: 0;
      transition: opacity 200ms ease;
    }

    &:hover{

      &::before{
        opacity: 0.5;
      }
    }

    &.active{

      &::before{
        opacity: 1;
      }
    }
  }

  span{
    text-transform: uppercase;
    font-size: var(--font-size-global);
    letter-spacing: var(--letter-spacing-global);
  }

  i{
    text-transform: lowercase;
    font-style: normal;
    font-weight: 100;
    font-family: 'monument_extendedultralight';
    opacity: .85;
  }
}

/*  */
/* LOGO */
/*  */
#logo{
  flex : 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 4rem;

  & > a{
    display: block;
    width: var(--length-logo);
    height: var(--length-logo);
    position: relative;
    perspective: 1000px;
    margin-bottom: 0.4rem;

    &:hover{
      span{
        &:first-of-type{
          transform: rotateY(360deg);
        }
        &:last-of-type{
          transform: rotateY(-180deg);
        }
      }
    }

    span{
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition : transform 2s ease;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;

      &:first-of-type{
        path{
          fill : var(--white);
        }
      }
      &:last-of-type{
        transform: rotateY(180deg);
        path{
          fill :var(--white);
          opacity: .7;
        }
      }
    }
  }
}

/*  */
/* APP */
/*  */
main[role=main]{
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-container{
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);

  g{
    transform-origin: center center;
  }
}


@media screen and (max-width: 1024px){

  header[role=banner]{
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  h1, #logo{
    flex: unset;
    line-height: 1;
  }

  nav{
    flex: 1;
    margin-left: var(--padding-global);
  }
}





body {
  background: #333333
}

header {
  margin: 0;
  padding: 0;
}

.title-block {
  position: relative;
  padding-left: 60px;

}

h1.font-size-90 {
  font-family: 'Poppins', sans-serif;
  font-size: 80px;
  line-height: 0.98em;
  font-weight: 700;
  margin-top: -15px;

}

h1.font-size-90 span {
  font-size: 30px;
  vertical-align: text-top;
}

h2 {
  font-family: 'Poppins', sans-serif;
  background: #28b1df;
  color: #333333;
  font-weight: 400;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  position: absolute;
  top: 45px;
  left: -50px;
  display: inline-block;
  padding:15px;
  width: 145px;
  text-align:Center;
}

footer {
  position: fixed;
  bottom: 0;
  padding: 20px;
  width: 100%
}

footer p {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color:#a7a7a7;
}

footer p br {
  display: none
}

footer p a {
  color: #fff;
  background: #000;
  padding: 10px 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  display: inline-block;
  transition: all 0.3s ease;
}

footer p a:hover,
footer p a:focus,
footer p a:active {
  background: #28b1df;
  color: #333;
}


.art-logo {
  position: absolute;
  right: 20px;
  bottom:20px;
}


@media screen and (max-width: 1190px){
  footer p br {
    display: block
  }
}


@media screen and (max-width: 830px){
  .art-logo {
    position: relative;
    right: auto;
    bottom:auto;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px){
  h1.font-size-90 {
    font-size: 40px;
    line-height: 0.98em;
    margin-top: 30px;

  }

  h1.font-size-90 span {
    font-size: 15px;
    vertical-align: text-top;
  }
}
